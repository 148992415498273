// routes
import Stripe from 'stripe';

export const HOST_API = process.env.NEXT_PUBLIC_HOST_API_URL;

export const ASSETS_API = process.env.NEXT_PUBLIC_ASSETS_API;

export const READY_PLAYER_API = process.env.NEXT_PUBLIC_READY_PLAYER_ME_API;
export const READY_PLAYER_APP_ID = process.env.NEXT_PUBLIC_READY_PLAYER_ME_APP_ID;
export const READY_PLAYER_API_KEY = process.env.NEXT_PUBLIC_READY_PLAYER_ME_API_KEY;
export const READY_PLAYER_ME_MODELS_API = process.env.NEXT_PUBLIC_READY_PLAYER_ME_MODELS_API;

export const FIREBASE_API = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APPID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
  vapidKey: process.env.NEXT_PUBLIC_WEB_PUSH_CERTIFICATE,
};

export const POSTHOG = {
  publicKey: process.env.NEXT_PUBLIC_POSTHOG_KEY,
  host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
};
export const ALGOLIA_API = {
  appId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || '',
  apiKey: process.env.NEXT_PUBLIC_ALGOLIA_API_KEY || '',
  index: process.env.NEXT_PUBLIC_ALGOLIA_INDEX || '',
};
export const AMPLIFY_API = {
  userPoolId: process.env.NEXT_PUBLIC_AWS_AMPLIFY_USER_POOL_ID,
  userPoolWebClientId: process.env.NEXT_PUBLIC_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  region: process.env.NEXT_PUBLIC_AWS_AMPLIFY_REGION,
};

export const AUTH0_API = {
  clientId: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID,
  domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
  callbackUrl: process.env.NEXT_PUBLIC_AUTH0_CALLBACK_URL,
};

export const MAPBOX_API = process.env.NEXT_PUBLIC_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = '/'; // as '/dashboard'

if (!process.env.NEXT_PUBLIC_STRIPE_TEST_SECRET_KEY) {
  throw new Error('STRIPE_TEST_SECRET_KEY is missing. Please set the environment variable.');
}

export const STRIPE_PUBLISH_KEY = process.env.NEXT_PUBLIC_STRIPE_TEST_SECRET_KEY;
export const stripe = new Stripe(process.env.NEXT_PUBLIC_STRIPE_TEST_SECRET_KEY, {
  apiVersion: '2023-10-16',
});

export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

export const IS_STATIC_EXPORT = true;
