import { useMutation, useQuery } from '@tanstack/react-query';
import queryClient from 'src/services/queryClient';
import { ICreateChannel } from 'src/types';
import { PaginationQuery } from 'src/types/pagination';
import { StreamChat } from 'stream-chat';

import { createTrybeChannel, getMatchesList } from '../connect';

const client = StreamChat.getInstance('y7fsjd33c926');

export function useGetMatches(params?: PaginationQuery, enabled?: boolean) {
  return useQuery({
    queryKey: ['connects'],
    queryFn: async () => getMatchesList(params),
    refetchInterval: 15000,
    enabled,
    refetchOnWindowFocus: true,
  });
}
export function useCreateTrybeChannel() {
  return useMutation({
    mutationFn: async (data: ICreateChannel) => createTrybeChannel(data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['connects'] });
      await queryClient.invalidateQueries({ queryKey: ['channels'] });
    },
  });
}

export function useGetChannel(params?: any, enabled?: boolean) {
  return useQuery({
    queryKey: ['channels'],
    queryFn: async () => client.queryChannels(params.filters, params.sort, params.options),
    refetchInterval: 15000,
    enabled,
    refetchOnWindowFocus: true,
  });
}
